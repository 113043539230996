import React from'react';
import Loader from'@24i/nxg-sdk-gluons/src/components/ui/Loader';

var Index=function Index(){return(
React.createElement(Loader,{
additionalStyles:{
minHeight:'100%',
justifyContent:'center'
}}
));};


export default Index;